import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import SimpleReactLightbox from "simple-react-lightbox"

import Layout from "../hoc/Layout"
import SectionAction from "../components/Sections/SectionAction/SectionAction"
import SectionIntro from "../components/Sections/SectionIntro/SectionIntro"
import Lightbox from "../components/Lighbox/Lightbox"

const events = ({ data }) => {
  return (
    <SimpleReactLightbox>
      <Layout headerPages pages>
        <SectionIntro page="events" />

        <div className="events">
          <section className="container info-box">
            <div className="row">
              <div className="col-6 col-md-12">
                <h2>Adding value to your event</h2>
                <p>Events may include early education and English teaching conferences, child and family-oriented exhibitions (focusing on childcare, education, family lifestyle etc.), plus family events in the local community and many more.</p>
                <p>
                  The display can be designed in a variety of attractive ways, bringing colour and adding interest among exhibitor stands. Moonwood offers the possibility of organising children's activities alongside, including storytelling, colouring in and simple craft
                  activities, suitable up to the age of twelve years.
                </p>
              </div>
              <div className="col-6 col-md-12">
                <div className="divider hide">
                  <div className="divider-inner-width"></div>
                </div>
                <div className="image-container">
                  <Img fluid={data.file.childImageSharp.fluid} alt="books" />
                </div>
                <div className="divider show hide">
                  <div className="divider-inner-height-rotate"></div>
                </div>
              </div>
            </div>
          </section>

          <Lightbox />

          <SectionAction text="Would you like to have a display<br/>of English children&#39;s books at your event?" buttonText="Contact Us" buttonLink="mailto:info@moonwood-books.com?subject=Requesting a display of English children's books" />
        </div>
      </Layout>
    </SimpleReactLightbox>
  )
}

export default events

export const query = graphql`
  query {
    file(relativePath: { eq: "events/events-image.png" }) {
      childImageSharp {
        fluid(maxWidth: 638, maxHeight: 459, pngQuality: 90, quality: 90) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
          presentationHeight
          presentationWidth
        }
      }
    }
  }
`
