import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { SRLWrapper } from "simple-react-lightbox"
import { useLightbox } from "simple-react-lightbox"

const options = {
  settings: {
    overlayColor: "rgba(255, 255, 255, 0.64)",
    disablePanzoom: true,
    hideControlsAfter: false,
  },
  buttons: {
    showAutoplayButton: false,
    showDownloadButton: false,
    showFullscreenButton: false,
    showThumbnailsButton: false,
  },
  thumbnails: {
    showThumbnails: false,
  },
  caption: {
    showCaption: false,
  },
}

const LightboxSection = () => {
  const { closeLightbox } = useLightbox()

  let windowSize

  if (typeof window !== "undefined") {
    windowSize = window.innerWidth
  } else {
    windowSize = 0
  }

  const [lightboxOpen, setLightboxOpen] = useState(false)
  const [width, setWidth] = useState(windowSize)

  React.useEffect(() => {
    const handleLightbox = () => {
      closeLightbox()
      setLightboxOpen(false)
    }
    if (lightboxOpen === true && width <= 768) {
      document.addEventListener("click", handleLightbox)
    }
    return () => {
      document.removeEventListener("click", handleLightbox)
    }
  }, [lightboxOpen, closeLightbox, width])

  React.useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [width])

  const click = () => {
    setLightboxOpen(true)
  }
  const keyClick = code => {
    if (code.keyCode === 13 || code.which === 13) {
      setLightboxOpen(true)
    }
  }

  const query = useStaticQuery(graphql`
    query {
      allFile(sort: { order: ASC, fields: name }, filter: { relativeDirectory: { eq: "lightbox" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 594, quality: 90, pngQuality: 90, srcSetBreakpoints: [690, 768, 960, 1600, 1920]) {
                ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluidLimitPresentationSize
                base64
                presentationHeight
                presentationWidth
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
          }
        }
      }
    }
  `)
  const eventImage1 = query.allFile.edges[0].node.childImageSharp.fluid
  const eventImage2 = query.allFile.edges[1].node.childImageSharp.fluid
  const eventImage3 = query.allFile.edges[2].node.childImageSharp.fluid
  const eventImage4 = query.allFile.edges[3].node.childImageSharp.fluid
  const eventImage5 = query.allFile.edges[4].node.childImageSharp.fluid
  const eventImage6 = query.allFile.edges[5].node.childImageSharp.fluid
  const eventImage7 = query.allFile.edges[6].node.childImageSharp.fluid
  const eventImage8 = query.allFile.edges[7].node.childImageSharp.fluid
  const imagesFirst = [eventImage1, eventImage2, eventImage3, eventImage4, eventImage5, eventImage6, eventImage7, eventImage8]
  return (
    <section className="container lightbox">
      <SRLWrapper options={options}>
        <div className="row">
          {imagesFirst.map((el, i) => (
            <div className="col-3 col-md-12" onClick={click} onKeyDown={e => keyClick(e)} role="button" tabIndex="0" key={i}>
              <Img className="lightbox-image" fluid={el} alt="events" />
            </div>
          ))}
        </div>
      </SRLWrapper>
    </section>
  )
}

export default LightboxSection
